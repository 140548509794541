.circle-pulse .loader {
	width: 100px;
	height: 100px;
}

.circle-pulse .loader>div {
	margin: 23px 0 0 23px;
	width: 50%;
	height: 50%;
	border: 2px solid #4169E1;
	border-radius: 50%;
	animation: circlePulse 1s ease infinite;
}

@keyframes circlePulse {
	0% {
		transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

.circle-pulse-multiple .loader {
	width: 100px;
	height: 100px;
}

.circle-pulse-multiple .loader>div {
	position: absolute;
	top: 24px;
	left: 24px;
	width: 50%;
	height: 50%;
	border: 2px solid #1F9CE4;
	border-radius: 50%;
	animation: circlePulse 1s ease infinite;
}

.circle-pulse-multiple .loader .circle-2 {
	animation-delay: -.9s;
}

.circle-pulse-multiple .loader .circle-3 {
	animation-delay: -.8s;
}

@keyframes circlePulse {
	0% {
		transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}





#loaders::before,
#loaders::after {
	content: "";
	display: table;
}

#loaders::after {
	clear: both;
	overflow: hidden;
}

.loader-container .loader {
	position: absolute;
	top: 0;
	left: 0.6rem;
	right: 0;
	bottom: 0;
	margin: auto;
}

#loaders {
	margin: 0 auto;
	width: 11F9CE4px;
}

.loader-container {
	position: relative;
	float: left;
	width: 250px;
	height: 250px;
}

/*# sourceMappingURL=output.css.map */