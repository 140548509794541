@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);

.circle-pulse .loader {
	width: 100px;
	height: 100px;
}

.circle-pulse .loader>div {
	margin: 23px 0 0 23px;
	width: 50%;
	height: 50%;
	border: 2px solid #4169E1;
	border-radius: 50%;
	-webkit-animation: circlePulse 1s ease infinite;
	   -moz-animation: circlePulse 1s ease infinite;
	        animation: circlePulse 1s ease infinite;
}

@-webkit-keyframes circlePulse {
	0% {
		-webkit-transform: scale(0.1);
		        transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 0;
	}
}

@-moz-keyframes circlePulse {
	0% {
		-moz-transform: scale(0.1);
		     transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		-moz-transform: scale(1);
		     transform: scale(1);
		opacity: 0;
	}
}

@keyframes circlePulse {
	0% {
		-webkit-transform: scale(0.1);
		   -moz-transform: scale(0.1);
		        transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		        transform: scale(1);
		opacity: 0;
	}
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-moz-keyframes rotate {
	100% {
		-moz-transform: rotate(360deg);
		     transform: rotate(360deg);
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		   -moz-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.circle-pulse-multiple .loader {
	width: 100px;
	height: 100px;
}

.circle-pulse-multiple .loader>div {
	position: absolute;
	top: 24px;
	left: 24px;
	width: 50%;
	height: 50%;
	border: 2px solid #1F9CE4;
	border-radius: 50%;
	-webkit-animation: circlePulse 1s ease infinite;
	   -moz-animation: circlePulse 1s ease infinite;
	        animation: circlePulse 1s ease infinite;
}

.circle-pulse-multiple .loader .circle-2 {
	-webkit-animation-delay: -.9s;
	   -moz-animation-delay: -.9s;
	        animation-delay: -.9s;
}

.circle-pulse-multiple .loader .circle-3 {
	-webkit-animation-delay: -.8s;
	   -moz-animation-delay: -.8s;
	        animation-delay: -.8s;
}

@keyframes circlePulse {
	0% {
		-webkit-transform: scale(0.1);
		   -moz-transform: scale(0.1);
		        transform: scale(0.1);
	}

	80% {
		opacity: .8;
	}

	100% {
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		        transform: scale(1);
		opacity: 0;
	}
}





#loaders::before,
#loaders::after {
	content: "";
	display: table;
}

#loaders::after {
	clear: both;
	overflow: hidden;
}

.loader-container .loader {
	position: absolute;
	top: 0;
	left: 0.6rem;
	right: 0;
	bottom: 0;
	margin: auto;
}

#loaders {
	margin: 0 auto;
	width: 11F9CE4px;
}

.loader-container {
	position: relative;
	float: left;
	width: 250px;
	height: 250px;
}

/*# sourceMappingURL=output.css.map */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*{
	font-family: "Montserrat" !important;
}

/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 

}
 */

:root{
    --toastify-toast-width: 700px;
    --toastify-color-progress-light:var(--toastify-color-info);
}



